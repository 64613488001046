import { useEffect, useState } from 'react';
import React from 'react'
import { Navbar, Container, Nav } from 'react-bootstrap';
import api from '../../service/api';
import { DropdownButton, ButtonGroup, Dropdown } from 'react-bootstrap'
import Badge from '@mui/material/Badge';
import { useSelector, useDispatch } from 'react-redux';
import { accordionClick } from '../../redux/accordion/actions'
import { Link, useParams } from 'react-router-dom';
import logo from '../../image/clube-do-gas.png'
import { configurationTheme } from '../../redux/configurationTheme/actions'
import moment from 'moment';

const Header = () => {
    const [address, setAddress] = useState();
    const [address2, setAddress2] = useState();
    const [logoMenu, setLogoMenu] = useState(logo);
    const [color, setColor] = useState('#26398C');
    const [funcionalityHour, setFuncionalityHour] = useState(false);
    const [openHour, setOpenHour] = useState('')

    var db = window.location.pathname;

    const dispatch = useDispatch();

    const { currentTheme } = useSelector(rootReducer => rootReducer.configurationReducer);

    useEffect(() => {
        if (db == '/eskinao_gas') {
            api
                .get('/TodosPerfilRevendas/' + db)
                .then(response => {
                    const dateDay = new Date().getDate()
                    const dateMonth = new Date().getMonth()
                    const dateYear = new Date().getFullYear()
                    let operationHoliday = response.data[0].operation_holiday.split(' - ');
                    let operationWeek = response.data[0].operation_week.split(' - ');
                    let operationWeekend = response.data[0].operation_weekend.split(' - ');
                    let splitCloseWeek = new Date(dateYear, dateMonth, dateDay, operationWeek[1].split(':')[0], operationWeek[1].split(':')[1], '00').getTime()
                    let splitCloseWeekend = new Date(dateYear, dateMonth, dateDay, operationWeekend[1].split(':')[0], operationWeekend[1].split(':')[1], '00').getTime()
                    let splitCloseHoliday = new Date(dateYear, dateMonth, dateDay, operationHoliday[1].split(':')[0], operationHoliday[1].split(':')[1], '00').getTime()
                    let splitOpenWeek = new Date(dateYear, dateMonth, dateDay, operationWeek[0].split(':')[0], operationWeek[0].split(':')[1], '00').getTime()
                    let splitOpenWeekend = new Date(dateYear, dateMonth, dateDay, operationWeekend[0].split(':')[0], operationWeekend[0].split(':')[1], '00').getTime()
                    let splitOpenHoliday = new Date(dateYear, dateMonth, dateDay, operationHoliday[0].split(':')[0], operationHoliday[0].split(':')[1], '00').getTime()
                    const dateNow = new Date().getTime()
                    const dateNowDay = new Date().getDay()
                    // console.log(Number(splitCloseWeek[1]) <= Number(dateNow[1]))
                    if (dateNowDay == 0) {
                        setOpenHour(splitCloseHoliday[0] + 'h' + splitCloseHoliday[1])
                        if (dateNow >= splitOpenHoliday && dateNow <= splitCloseHoliday) {
                            setFuncionalityHour(false)
                        } else {
                            setFuncionalityHour(true)
                        }
                    } else if (dateNowDay <= 5) {
                        setOpenHour(splitOpenWeek[0] + 'h' + splitOpenWeek[1])
                        if (dateNow >= splitOpenWeek && dateNow <= splitCloseWeek) {
                            setFuncionalityHour(false)
                        } else {
                            setFuncionalityHour(true)
                        }
                    } else if (dateNowDay == 6) {
                        setOpenHour(operationWeekend[0] + 'h' + operationWeekend[1])
                        if (dateNow >= splitOpenWeekend && dateNow <= splitCloseWeekend) {
                            setFuncionalityHour(false)
                        } else {
                            setFuncionalityHour(true)
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [])

    useEffect(() => {
        api
            .get("/Configuracoes" + db)
            .then((response) => {
                response.data.map(key => {
                    setLogoMenu(key['logoMenu']);
                    dispatch(configurationTheme({ logoLogin: key['logoLogin'], logoMenu: key['logoMenu'], colorPrimary: key['colorPrimary'], colorSecundary: key['colorSecundary'] }));
                })
            })

            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    useEffect(() => {
        api
            .get('/TodosPerfilRevendas' + db)
            .then(response => {
                setAddress(response.data[0].address + ', ' + response.data[0].number)
                setAddress2(response.data[0].neighborhood + ' - ' + response.data[0].city + '/' + response.data[0].state);
            })
            .catch(err => {
                console.log(err)
            })
    }, [])
    // console.log(db)
    return (
        <Navbar expand="lg" className="navBar header" >
            <Container style={{ justifyContent: 'space-between', width: "40%" }}>
                <img src={logoMenu} width={87} />
                {funcionalityHour ? (
                    <span style={{ color: 'white', fontSize: 16, fontWeight: 'bold' }}>Estamos Fechados!<br />Voltamos amanhã às {openHour}!</span>
                ) : ('')}
                {db !== '/expresso_gas_' && db !== '/ats_telegas' ? (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ color: 'white', fontSize: 12, fontWeight: 400 }}>{address}</span>
                        <span style={{ color: 'white', fontSize: 12, fontWeight: 400 }}>{address2}</span>
                        {/* <span style={{ color: 'white', fontSize: 12, fontWeight: 400 }}>Entrega 60 min</span> */}
                    </div>
                ) : ('')}
            </Container>
        </Navbar>
    )
}

export default Header